import React from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import TaskItem from './TaskItem';
import { Task } from '../types/Task';

interface TaskListProps {
  tasks: Task[];
  onToggleComplete: (id: string) => void;
  onDelete: (id: string) => void;
  onReorder: (startIndex: number, endIndex: number) => void;
}

const TaskList: React.FC<TaskListProps> = ({ tasks, onToggleComplete, onDelete, onReorder }) => {
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    
    const { source, destination } = result;
    if (source.index === destination.index) return;
    
    onReorder(source.index, destination.index);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="tasks">
        {(provided) => (
          <TaskListContainer
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {tasks.length === 0 ? (
              <EmptyState>Нет задач. Добавьте новую задачу!</EmptyState>
            ) : (
              tasks.map((task, index) => (
                <TaskItem
                  key={task.id}
                  task={task}
                  index={index}
                  onToggleComplete={onToggleComplete}
                  onDelete={onDelete}
                />
              ))
            )}
            {provided.placeholder}
          </TaskListContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const TaskListContainer = styled.div`
  padding: 16px 0;
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 40px 16px;
  color: #888;
  font-size: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 16px 0;
`;

export default TaskList; 