import React, { createContext, useState, useContext, useEffect } from 'react';

// Объявляем тип для Telegram API
declare global {
  interface Window {
    Telegram?: {
      WebApp?: any;
    };
  }
}

// Fake WebApp API для локальной разработки, на продакшене будет использовано окно Telegram
const WebApp = {
  initDataUnsafe: {
    user: {
      id: 626192717 // ID пользователя по умолчанию для прямого доступа
    }
  }
};

// Проверяем, доступно ли Telegram WebApp API
if (window.Telegram && window.Telegram.WebApp) {
  console.log('[DEBUG AUTH] Найден Telegram WebApp API');
  Object.assign(WebApp, window.Telegram.WebApp);
} else {
  console.log('[DEBUG AUTH] Telegram WebApp API не найден, используем запасной вариант');
}

console.log('[DEBUG AUTH] Используемый WebApp:', WebApp.initDataUnsafe?.user?.id ? `ID: ${WebApp.initDataUnsafe.user.id}` : 'ID не найден');

interface AuthContextType {
  isAuthenticated: boolean;
  token: string | null;
  isLoading: boolean;
  error: string | null;
  login: () => Promise<boolean>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [token, setToken] = useState<string | null>(() => {
    const savedToken = localStorage.getItem('auth_token');
    console.log('[DEBUG AUTH] Токен из localStorage:', savedToken ? 'Найден' : 'Не найден');
    return savedToken;
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Эффект для установки токена в заголовки axios (можно добавить при необходимости)
  useEffect(() => {
    console.log('[DEBUG AUTH] Аутентификация:', token ? 'Есть' : 'Нет');
  }, [token]);

  const login = async (): Promise<boolean> => {
    try {
      console.log('[DEBUG AUTH] Попытка входа');
      setIsLoading(true);
      setError(null);

      // Получаем userId из Telegram WebApp, если доступен
      let userId = 0;
      
      if (WebApp.initDataUnsafe?.user?.id) {
        userId = WebApp.initDataUnsafe.user.id;
        console.log('[DEBUG AUTH] ID пользователя из WebApp:', userId);
      } else {
        console.log('[DEBUG AUTH] ID пользователя не найден в WebApp');
        throw new Error('Не удалось получить Telegram User ID. Пожалуйста, откройте приложение через Telegram.');
      }

      // Отправляем запрос на авторизацию
      console.log('[DEBUG AUTH] Отправка запроса на /api/auth');
      const response = await fetch('/api/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
      });

      console.log('[DEBUG AUTH] Статус ответа:', response.status);
      
      if (!response.ok) {
        const errorData = await response.json();
        console.log('[DEBUG AUTH] Ошибка авторизации:', errorData);
        throw new Error(errorData.error || 'Ошибка авторизации');
      }

      const data = await response.json();
      console.log('[DEBUG AUTH] Успешная авторизация, получен токен');
      
      // Сохраняем токен
      localStorage.setItem('auth_token', data.token);
      setToken(data.token);
      
      return true;
    } catch (err: any) {
      console.error('[DEBUG AUTH] Ошибка авторизации:', err);
      setError(err.message || 'Произошла ошибка при авторизации');
      return false;
    } finally {
      setIsLoading(false);
      console.log('[DEBUG AUTH] Завершение процесса входа');
    }
  };

  const logout = () => {
    console.log('[DEBUG AUTH] Выход из системы');
    localStorage.removeItem('auth_token');
    setToken(null);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!token,
        token,
        isLoading,
        error,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}; 
