import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Task } from '../types/Task';
import { Draggable } from 'react-beautiful-dnd';

interface TaskItemProps {
  task: Task;
  index: number;
  onToggleComplete: (id: string) => void;
  onDelete: (id: string) => void;
}

const TaskItem: React.FC<TaskItemProps> = ({ task, index, onToggleComplete, onDelete }) => {
  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided, snapshot) => (
        <TaskCard
          ref={provided.innerRef}
          {...provided.draggableProps}
          isDragging={snapshot.isDragging}
        >
          <TaskContent>
            <CheckboxContainer>
              <Checkbox
                type="checkbox"
                checked={task.completed}
                onChange={() => onToggleComplete(task.id)}
              />
            </CheckboxContainer>
            
            <TaskDetails>
              <TaskHeader>
                <TaskTitle completed={task.completed}>{task.title}</TaskTitle>
                <TaskDate>
                  {format(task.createdAt, 'd MMMM yyyy, HH:mm', { locale: ru })}
                </TaskDate>
              </TaskHeader>
              
              <TaskDescription completed={task.completed}>
                {task.description}
              </TaskDescription>
            </TaskDetails>
            
            <TaskActions>
              <DragHandle {...provided.dragHandleProps}>
                <DragIcon>≡</DragIcon>
              </DragHandle>
              <DeleteButton onClick={() => onDelete(task.id)}>×</DeleteButton>
            </TaskActions>
          </TaskContent>
        </TaskCard>
      )}
    </Draggable>
  );
};

// Styled Components
const TaskCard = styled.div<{ isDragging: boolean }>`
  margin-bottom: 16px;
  background-color: ${props => props.isDragging ? '#f0f9ff' : 'white'};
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  
  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
`;

const TaskContent = styled.div`
  display: flex;
  padding: 16px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 4px;
  margin-right: 12px;
`;

const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const TaskDetails = styled.div`
  flex: 1;
  min-width: 0;
`;

const TaskHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 8px;
`;

const TaskTitle = styled.h3<{ completed: boolean }>`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  text-decoration: ${props => props.completed ? 'line-through' : 'none'};
  color: ${props => props.completed ? '#888' : '#333'};
`;

const TaskDate = styled.span`
  flex-shrink: 0;
  font-size: 14px;
  color: #888;
  margin-left: 8px;
`;

const TaskDescription = styled.p<{ completed: boolean }>`
  margin: 0;
  font-size: 16px;
  color: ${props => props.completed ? '#888' : '#666'};
  text-decoration: ${props => props.completed ? 'line-through' : 'none'};
  line-height: 1.5;
  word-wrap: break-word;
`;

const TaskActions = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border: none;
  background-color: transparent;
  color: #888;
  font-size: 24px;
  cursor: pointer;
  border-radius: 4px;
  
  &:hover {
    background-color: #ffebee;
    color: #e53935;
  }
`;

const DragHandle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  cursor: grab;
  color: #888;
  margin-bottom: 8px;
  border-radius: 4px;
  
  &:hover {
    background-color: #f5f5f5;
  }
  
  &:active {
    cursor: grabbing;
  }
`;

const DragIcon = styled.span`
  font-size: 20px;
`;

export default TaskItem; 