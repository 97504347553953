import React from 'react';
import styled from 'styled-components';
import { useAuth } from '../context/AuthContext';

const LoginForm: React.FC = () => {
  const { login, isLoading, error } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await login();
  };

  return (
    <LoginContainer>
      <Form onSubmit={handleSubmit}>
        <FormHeader>
          <Title>Вход в систему</Title>
          <Description>
            Для входа в систему нажмите на кнопку ниже. 
            Вход доступен только авторизованным пользователям.
          </Description>
        </FormHeader>

        {error && (
          <ErrorMessage>
            {error}
          </ErrorMessage>
        )}

        <FormFooter>
          <SubmitButton type="submit" disabled={isLoading}>
            {isLoading ? <LoadingSpinner /> : 'Войти'}
          </SubmitButton>
        </FormFooter>
      </Form>
    </LoginContainer>
  );
};

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const Form = styled.form`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
  max-width: 400px;
`;

const FormHeader = styled.div`
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
`;

const Description = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 16px;
`;

const ErrorMessage = styled.div`
  color: #f44336;
  font-size: 14px;
  margin-bottom: 16px;
  padding: 10px;
  background-color: #ffebee;
  border-radius: 4px;
`;

const FormFooter = styled.div`
  margin-top: 20px;
`;

const SubmitButton = styled.button`
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  
  &:hover {
    background-color: #45a049;
  }
  
  &:disabled {
    background-color: #9e9e9e;
    cursor: not-allowed;
  }
`;

const LoadingSpinner = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255,255,255,0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s ease-in-out infinite;
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
`;

export default LoginForm; 
