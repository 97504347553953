import React, { createContext, useState, useContext, useEffect } from 'react';
import { Task } from '../types/Task';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from './AuthContext';

interface TaskContextType {
  tasks: Task[];
  addTask: (title: string, description: string) => void;
  deleteTask: (id: string) => void;
  toggleTaskCompletion: (id: string) => void;
  reorderTasks: (startIndex: number, endIndex: number) => void;
  isLoading: boolean;
  error: string | null;
}

const TaskContext = createContext<TaskContextType | undefined>(undefined);

export const useTaskContext = () => {
  const context = useContext(TaskContext);
  if (!context) {
    throw new Error('useTaskContext must be used within a TaskProvider');
  }
  return context;
};

export const TaskProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { token, isAuthenticated } = useAuth();

  // Загрузка задач с сервера при монтировании компонента и авторизации
  useEffect(() => {
    console.log('[DEBUG TASKS] Эффект загрузки задач. Авторизован:', isAuthenticated, 'Токен:', token ? 'Есть' : 'Нет');
    if (isAuthenticated && token) {
      console.log('[DEBUG TASKS] Запуск загрузки задач');
      fetchTasks();
    } else {
      console.log('[DEBUG TASKS] Загрузка задач пропущена - нет авторизации');
    }
  }, [isAuthenticated, token]);

  const fetchTasks = async () => {
    if (!token) {
      console.log('[DEBUG TASKS] fetchTasks: Нет токена, отмена запроса');
      return;
    }

    try {
      console.log('[DEBUG TASKS] Начало запроса задач');
      setIsLoading(true);
      
      const authHeader = `Bearer ${token}`;
      console.log('[DEBUG TASKS] Заголовок авторизации:', authHeader.substring(0, 15) + '...');
      
      const response = await fetch('/api/tasks', {
        headers: {
          'Authorization': authHeader
        }
      });
      
      console.log('[DEBUG TASKS] Статус ответа:', response.status);
      
      if (!response.ok) {
        const errorText = await response.text();
        console.log('[DEBUG TASKS] Ошибка загрузки задач, текст:', errorText);
        throw new Error(`Ошибка HTTP: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('[DEBUG TASKS] Получено задач:', data.length);
      
      // Преобразуем строковые даты в объекты Date
      const tasksWithDates = data.map((task: any) => ({
        ...task,
        createdAt: new Date(task.createdAt)
      }));
      
      setTasks(tasksWithDates);
      setError(null);
      console.log('[DEBUG TASKS] Задачи успешно загружены');
    } catch (err) {
      console.error('[DEBUG TASKS] Ошибка при загрузке задач:', err);
      setError('Не удалось загрузить задачи. Проверьте соединение с сервером.');
    } finally {
      setIsLoading(false);
      console.log('[DEBUG TASKS] Завершение загрузки задач');
    }
  };

  // Сохраняем задачи на сервере при их изменении
  const saveTasks = async (updatedTasks: Task[]) => {
    if (!token) {
      console.log('[DEBUG TASKS] saveTasks: Нет токена, отмена запроса');
      return;
    }

    try {
      console.log('[DEBUG TASKS] Сохранение задач, количество:', updatedTasks.length);
      const response = await fetch('/api/tasks', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(updatedTasks),
      });
      
      console.log('[DEBUG TASKS] Статус сохранения задач:', response.status);
      
      if (!response.ok) {
        const errorText = await response.text();
        console.log('[DEBUG TASKS] Ошибка сохранения задач, текст:', errorText);
        throw new Error(`Ошибка HTTP: ${response.status}`);
      }
      
      setError(null);
      console.log('[DEBUG TASKS] Задачи успешно сохранены');
    } catch (err) {
      console.error('[DEBUG TASKS] Ошибка при сохранении задач:', err);
      setError('Не удалось сохранить изменения. Проверьте соединение с сервером.');
    }
  };

  const addTask = async (title: string, description: string) => {
    if (!token) {
      console.log('[DEBUG TASKS] addTask: Нет токена, отмена запроса');
      return;
    }

    try {
      console.log('[DEBUG TASKS] Добавление новой задачи:', title);
      const newTask: Task = {
        id: uuidv4(),
        title,
        description,
        createdAt: new Date(),
        completed: false,
      };
      
      const response = await fetch('/api/tasks', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newTask),
      });
      
      console.log('[DEBUG TASKS] Статус добавления задачи:', response.status);
      
      if (!response.ok) {
        const errorText = await response.text();
        console.log('[DEBUG TASKS] Ошибка добавления задачи, текст:', errorText);
        throw new Error(`Ошибка HTTP: ${response.status}`);
      }
      
      const updatedTasks = [...tasks, newTask];
      setTasks(updatedTasks);
      setError(null);
      console.log('[DEBUG TASKS] Задача успешно добавлена');
    } catch (err) {
      console.error('[DEBUG TASKS] Ошибка при добавлении задачи:', err);
      setError('Не удалось добавить задачу. Проверьте соединение с сервером.');
    }
  };

  const deleteTask = async (id: string) => {
    if (!token) {
      console.log('[DEBUG TASKS] deleteTask: Нет токена, отмена запроса');
      return;
    }

    try {
      console.log('[DEBUG TASKS] Удаление задачи с ID:', id);
      const response = await fetch(`/api/tasks/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      console.log('[DEBUG TASKS] Статус удаления задачи:', response.status);
      
      if (!response.ok) {
        const errorText = await response.text();
        console.log('[DEBUG TASKS] Ошибка удаления задачи, текст:', errorText);
        throw new Error(`Ошибка HTTP: ${response.status}`);
      }
      
      const updatedTasks = tasks.filter(task => task.id !== id);
      setTasks(updatedTasks);
      setError(null);
      console.log('[DEBUG TASKS] Задача успешно удалена');
    } catch (err) {
      console.error('[DEBUG TASKS] Ошибка при удалении задачи:', err);
      setError('Не удалось удалить задачу. Проверьте соединение с сервером.');
    }
  };

  const toggleTaskCompletion = (id: string) => {
    if (!token) {
      console.log('[DEBUG TASKS] toggleTaskCompletion: Нет токена, отмена запроса');
      return;
    }

    console.log('[DEBUG TASKS] Изменение статуса задачи с ID:', id);
    const updatedTasks = tasks.map(task =>
      task.id === id ? { ...task, completed: !task.completed } : task
    );
    setTasks(updatedTasks);
    saveTasks(updatedTasks);
  };

  const reorderTasks = (startIndex: number, endIndex: number) => {
    if (!token) {
      console.log('[DEBUG TASKS] reorderTasks: Нет токена, отмена запроса');
      return;
    }

    console.log('[DEBUG TASKS] Изменение порядка задач:', startIndex, '->', endIndex);
    const newTasks = [...tasks];
    const [removed] = newTasks.splice(startIndex, 1);
    newTasks.splice(endIndex, 0, removed);
    setTasks(newTasks);
    saveTasks(newTasks);
  };

  return (
    <TaskContext.Provider
      value={{
        tasks,
        addTask,
        deleteTask,
        toggleTaskCompletion,
        reorderTasks,
        isLoading,
        error,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
}; 
