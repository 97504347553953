import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { useAuth } from './context/AuthContext';
import TasksPage from './pages/TasksPage';
import LoginForm from './components/LoginForm';

const AppContent: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <>
      <GlobalStyle />
      {!isAuthenticated ? <LoginForm /> : <TasksPage />}
    </>
  );
};

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Roboto', 'Segoe UI', Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
    color: #333;
    line-height: 1.5;
  }
`;

export default AppContent; 
