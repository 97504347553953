import React, { useState } from 'react';
import styled from 'styled-components';
import TaskList from '../components/TaskList';
import AddTaskModal from '../components/AddTaskModal';
import { useTaskContext } from '../context/TaskContext';

const TasksPage: React.FC = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const { tasks, addTask, deleteTask, toggleTaskCompletion, reorderTasks, isLoading, error } = useTaskContext();

  return (
    <Container>
      <Header>
        <Title>Список задач</Title>
        <AddButton onClick={() => setIsAddModalOpen(true)}>
          Добавить задачу
        </AddButton>
      </Header>

      {isLoading ? (
        <LoadingContainer>
          <LoadingText>Загрузка задач...</LoadingText>
        </LoadingContainer>
      ) : error ? (
        <ErrorContainer>
          <ErrorText>{error}</ErrorText>
        </ErrorContainer>
      ) : (
        <TaskList
          tasks={tasks}
          onToggleComplete={toggleTaskCompletion}
          onDelete={deleteTask}
          onReorder={reorderTasks}
        />
      )}

      <AddTaskModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onAddTask={addTask}
      />
    </Container>
  );
};

// Styled Components
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  color: #333;
`;

const AddButton = styled.button`
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #1976d2;
  }
  
  &:active {
    background-color: #0d47a1;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

const LoadingText = styled.p`
  font-size: 18px;
  color: #666;
`;

const ErrorContainer = styled.div`
  background-color: #ffebee;
  border: 1px solid #f44336;
  border-radius: 4px;
  padding: 15px;
  margin: 20px 0;
`;

const ErrorText = styled.p`
  color: #d32f2f;
  margin: 0;
  font-size: 16px;
`;

export default TasksPage; 
